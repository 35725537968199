import * as React from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Homepage = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  height: 100%;
  width: 100%;

  & h1 {
    text-align: center;
    margin: 50px 0;
  }
`;

const NotFoundPage = () => (
  <>
    <Helmet title={`Treatment Studio`} />
    <Homepage>
      <h1>Page Not Found</h1>
    </Homepage>
  </>
);

export default withPrismicUnpublishedPreview(NotFoundPage);
